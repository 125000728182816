<script>
export default {
  name: 'ChipRow',
  props: {
    alarmObject: {
      type: Object,
      required: true,
      default: () => ({
        status: []
      })
    }
  },
  computed: {
    safeStatus() {
      return this.alarmObject.status || []
    }
  },
  methods: {
    alarm(age) {
      if (this.safeStatus.includes(`active_${age}`)) {
        return 'red'
      } else if (this.safeStatus.includes(`inactive_${age}`)) {
        return 'grey white--text'
      } else {
        return ''
      }
    }
  }
}
</script>

<template lang="pug">
div
  v-chip(
    v-for="age in ['1h', '24h', '7d']"
    :key="age"
    class="alarm"
    :color="alarm(age)"
    target="_blank"
    x-small
  )
    | {{ age }}
</template>
