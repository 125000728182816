<script>
import CandybarChart from '../candybar_chart.vue'
import KPI from '../elements/kpi.vue'
import SolarProductionChart from '../solar_production_chart.vue'
import PowerCurve from '../power_curve.vue'
import KpiLosses from '../elements/kpi_losses.vue'

export default {
  name: 'OmSolarInverters',
  components: { KPI, CandybarChart, SolarProductionChart, PowerCurve, KpiLosses },
  props: {
    inverters: Array,
    currentRange: Array,
    activeComponent: Object,
    comparingFromUrl: Array
  },
  async mounted() {
    if (!this.comparingInverters.length) await this.load()
  },
  data() {
    return {
      activeInverter: this.activeComponent,
      comparingInverters: [],
      comparingPowerCurves: [],
      copiedRange: this.currentRange,
      tmpComparison: [],
      productionStatus: [],
      inverterProduction: [],
      powerCurve: {},
      kpis: {},
      alarmChart: [{ title: 'NONE', values: [] }]
    }
  },
  created() {
    if (this.comparingFromUrl.length) {
      this.comparingInverters = this.inverters
        .filter((i) => this.comparingFromUrl.includes(i.id))
        .sort((a, b) => a.name.localeCompare(b.name))
      this.tmpComparison = this.comparingInverters
    } else {
      this.comparingInverters = []
      this.tmpComparison = []
    }
  },
  watch: {
    async currentRange() {
      if (this.currentRange[0] !== this.copiedRange[0] || this.currentRange[1] !== this.copiedRange[1]) {
        this.copiedRange = this.currentRange
        await this.load()
      }
    },
    async activeComponent() {
      this.activeInverter = this.activeComponent
      await this.load()
    },
    comparingInverters: {
      async handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue))
          await this.load()
      }
    }
  },
  computed: {
    otherInverters() {
      return this.inverters.filter((i) => i.id !== this.activeInverter.id)
    },
    assembledNames() {
      return this.comparingInverters.map((ci) => ci.name)
    },
    isComparing() {
      return this.comparingInverters.filter((e) => Object.keys(e).length).length !== 0
    },
    comparingLength() {
      return this.comparingInverters.filter((e) => Object.keys(e).length).length
    },
    comparingCurvesReady() {
      return (
        this.comparingPowerCurves.length > 0 &&
        this.comparingPowerCurves.every((curve) => Object.keys(curve).length > 0)
      )
    },
    powerCurveHasData() {
      return Object.keys(this.powerCurve).length > 0
    }
  },
  methods: {
    reset() {
      this.kpis = {}
      this.alarmChart = [{ title: 'NONE', values: [] }]
      this.productionStatus = []
      this.inverterProduction = []
      this.powerCurve = {}
    },
    async load() {
      this.reset()
      await Promise.all([
        this.loadKPIs(),
        this.loadAlarmChart(),
        this.loadProductionStatus(),
        this.loadInverterProduction()
      ])

      if (this.isComparing) {
        let powerCurveCache = []
        this.comparingPowerCurves = []
        for await (const comparingInverter of this.comparingInverters.sort((a, b) =>
          a.name.localeCompare(b.name)
        )) {
          if (comparingInverter.id) {
            const pcData = await this.loadPowerCurve(comparingInverter.id)
            powerCurveCache.push({ id: comparingInverter.id, data: pcData })
          }
        }
        this.comparingPowerCurves = powerCurveCache.map((i) => i.data)
      }
      this.powerCurve = await this.loadPowerCurve(this.activeInverter.id)
    },
    changeActiveInverter(inverterId) {
      this.activeComponent = this.inverters.filter((i) => i.id === inverterId)[0]
    },
    cleanComparisonList(comparisonIndex) {
      return this.inverters.filter(
        (i) =>
          (!this.comparingInverters.includes(i) || this.comparingInverters.indexOf(i) === comparisonIndex) &&
          this.activeInverter !== i
      )
    },
    compareAnother() {
      if (this.comparingInverters.length < 5) {
        this.comparingInverters.push({})
      }
    },
    activeInverterIdsUrl() {
      let id1 = `inverter_ids[]=${this.activeInverter.id}`
      let addOn = '&'
      if (this.isComparing) {
        this.comparingInverters.forEach((cm) => {
          addOn += `inverter_ids[]=${cm.id}&`
        })
      }
      return id1 + addOn.replace(/\&$/, '')
    },
    removeComparison(index) {
      this.comparingInverters.splice(index, 1)
      if (this.comparingInverters.length === 0) {
        this.comparingInverters = [{}]
      }
    },
    async loadKPIs() {
      const response = await this.axios.get(
        `/om_dashboard/inverter_kpis/${this.activeInverter.id}/?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.kpis = response.data
    },
    async loadProductionStatus() {
      let response = await this.axios.get(
        `/om_dashboard/operational_status_inverter?${this.activeInverterIdsUrl()}&start_time=${
          this.currentRange[0]
        }&end_time=${this.currentRange[1]}`
      )
      this.productionStatus = response.data
    },
    async loadInverterProduction() {
      let response = await this.axios.get(
        `/om_dashboard/production_in_kw_per_inverter?${this.activeInverterIdsUrl()}&start_time=${
          this.currentRange[0]
        }&end_time=${this.currentRange[1]}`
      )
      if (this.comparingInverters.length) {
        this.inverterProduction = [response.data.find((i) => i.title === this.activeInverter.name)]
        if (response.data.length > 1) {
          const others = response.data
            .filter((i) => i.title !== this.activeInverter.name)
            .sort((a, b) => a.title.localeCompare(b.title))
          this.inverterProduction.push(...others)
        }
      } else this.inverterProduction = response.data
    },
    async loadPowerCurve(inverterId) {
      let response = await this.axios.get(
        `/om_dashboard/power_curve_for_inverter/${inverterId}/?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      return response.data
    },
    async loadAlarmChart() {
      let response = await this.axios.get(
        `/om_dashboard/alarm_chart?${this.activeInverterIdsUrl()}&start_time=${
          this.currentRange[0]
        }&end_time=${this.currentRange[1]}`
      )
      this.alarmChart = response.data
    },
    limiter(e) {
      const selectRef = this.$refs.comparisonSelect
      if (selectRef) selectRef.blur()
      if (e.length > 5) e.pop()
    },
    syncComparisons() {
      if (this.tmpComparison.length > 5) this.tmpComparison = this.tmpComparison.slice(0, 5)
      this.comparingInverters = this.tmpComparison
      this.$emit(
        'changedComparisons',
        this.comparingInverters.map((i) => i.id)
      )
    }
  }
}
</script>

<template lang="pug">
div.asset_wrapper
  v-container.comparison_selector
    v-row
      v-col.d-flex(cols="2")
        div(style="align-self:flex-start") {{ $t('om_dashboard.drilldown.compare_with_inverter', { inverter_name: activeInverter.name }) }}
      v-col.d-flex(cols="4")
        v-select.compare_inverter(:items="otherInverters" multiple ref="comparisonSelect" v-model="tmpComparison" item-value="id" item-text="name" hide-details="auto" @blur="syncComparisons" return-object outlined)

  v-container
    .kpi_wrapper(v-if="Object.keys(kpis).length > 0")
      v-row
        v-col.graph_title {{ $t('om_dashboard.drilldown.key_performance_for', { name: activeInverter.name}) }}
      v-row
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_produced')" :kpis="kpis.energy_produced" field="eac_meas_mwh" unit=" MWh" :showPercentage="true")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_losses')" :kpis="kpis.losses" field="eac_loss_mwh" :invertArrows="true" unit=" MWh")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.time_based_availability')" :kpis="kpis.time_availability" field="weighted_technical_av" :tooltips="['expected_operating_hours', 'downtime_hours']" unit="%")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_based_availability')" :kpis="kpis.energy_based_availability" field="energy_based_av" unit="%")

  v-container
    v-row
      v-col.graph_title
        | {{ $t('om_dashboard.drilldown.operational_status_of_inverter', { name: activeInverter.name }) }}
        span(v-if="comparingInverters.length") &nbsp;+ {{ comparingInverters.length }}
    v-row
      v-col
    CandybarChart(:values="alarmChart" :showAlarmLegend="true" tech="solar")

  v-container
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.operational_status_of_mppts', { name: activeInverter.name }) }}

    v-row
      v-col
    CandybarChart(title="MPPT" :values="productionStatus" :additionalNames="assembledNames" tech="solar")

  v-container
    v-row
      v-col.graph_title
        | {{ $t('om_dashboard.drilldown.production_for_inverter', { name: activeInverter.name }) }}
        span(v-if="comparingInverters.length") &nbsp;+ {{ comparingLength }}
    v-row
      v-col
    SolarProductionChart(v-if="inverterProduction && inverterProduction.length > 0" :values="inverterProduction" :additionalNames="assembledNames" :unit="'kW'" :canNormalize="true")

  v-container(v-if="powerCurveHasData")
    v-row
      v-col.left-aligned(cols="6")
        v-row
          v-col.graph_title {{ $t('om_dashboard.drilldown.power_curve_for', { name: activeInverter.name }) }}
        v-row(v-if="Object.keys(kpis).length > 0")
          KpiLosses.graph_subtitle(:losses="kpis.losses.requested.eac_loss_mwh")
        v-row
          v-col
            PowerCurve(
              :values="powerCurve"
              :x_title="$t('om_dashboard.drilldown.power_curve_inv_x_title')"
              :y_title="$t('om_dashboard.drilldown.power_curve_y_title')"
              :unit="$t('om_dashboard.drilldown.power_curve_unit_solar')"
              tech="solar"
            )
      v-col.right-aligned(cols="6" v-if="powerCurveHasData && isComparing && comparingCurvesReady")
        v-row
          v-col.graph_title(class="text-right")
            | {{ $t('om_dashboard.drilldown.compared_power_curves') }}
            | {{ activeInverter.name }}
            span(v-if="comparingLength === 1") &nbsp;&&nbsp; {{ assembledNames[0] }}
            span(v-else) &nbsp;+ {{ comparingLength }}
        v-row.graph_subtitle
          v-col &nbsp;
        v-row
          v-col
            PowerCurve(
              :values="powerCurve"
              :name="activeInverter.name"
              :additionalNames="assembledNames"
              :additionalValues="comparingPowerCurves"
              :x_title="$t('om_dashboard.drilldown.power_curve_x_title')"
              :y_title="$t('om_dashboard.drilldown.power_curve_y_title')"
              :unit="$t('om_dashboard.drilldown.power_curve_unit_solar')"
              tech="solar"
            )
</template>
