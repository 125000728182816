<script>
export default {
  props: {
  },
  data() {
    return {
      ready: false,
      key: 1,
      volume_series: [],
      price_series: [],
      currencyCode: '',
      prices_min_value: 0,
      volume_min_value: 0,
      scope: '1m',
      buttons: [
        {type: 'hour', count: 24, text: '24h'},
        {type: 'day', count: 7, text: '7d'},
        {type: 'month', count: 1, text: '1m'},
        {type: 'month', count: 3, text: '3m'},
        {type: 'month', count: 6, text: '6m'},
        {type: 'ytd', text: 'YTD'},
        {type: 'year', count: 1, text: '1y'}
      ]
    }
  },
  computed: {
    selected_button_index() {
      const index = this.buttons.findIndex(b => b.text === this.scope)
      return index !== -1 ? index : 0 // Default to the first button if not found
    },
    chart_options() {
      let currencyCode = this.currencyCode
      let total_volume_translation = this.$t("dashboard.total_volume")

      return {
        chart: {
          zoomType: '',
          events: {
            load: () => {
              this.attachButtonListeners()
            }
          }
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        title: {
          text: null,
        },
        legend: {enabled: true},
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
        },
        yAxis: [{
          title: {
            text: null
          },
          labels: {
            formatter() {
              return this.value + ' MW'
            }
          },
          opposite: false,
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          min: this.volume_min_value,
          startOnTick: false,
          endOnTick: false,
          tickAmount: 6,
        }, {
          title: {
            text: null
          },
          labels: {
            formatter() {
              return this.value + ` ${currencyCode}/MWh`
            }
          },
          opposite: true,
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          min: this.prices_min_value,
          startOnTick: false,
          endOnTick: false,
          tickAmount: 6,
        }],
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            animation: false
          },
        },
        rangeSelector: {
          inputEnabled: false,
          allButtonsEnabled: true,
          selected: this.selected_button_index,
          buttons: this.buttons.map(button => ({
            type: button.type,
            count: button.count,
            text: button.text
          })),
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        series: [
          ...this.price_series,
          ...this.volume_series,
        ],
        tooltip: {
          split: false,
          shared: true,
          useHTML: true,
          formatter: function () {
            let total_volume = 0
            let html_price = []
            let html_volume = ""

            this.points.forEach(function (point) {
              let series_type = point.series.userOptions.series_type

              switch (series_type) {
                case 'price':
                  html_price.push(`${point.series.name.toUpperCase()} Price: ${point.y.toFixed(0)} ${currencyCode}/MWh`)
                  break
                case 'volume':
                  html_volume += `<div style="display: flex;">
                                    ${point.series.name}
                                    <div style=" flex-grow: 1;"></div>
                                    ${point.y.toFixed(3)} MW
                                  </div>`
                  total_volume += point.y
                  break
              }
            })

            let options = {
              weekday: 'long',
              month: 'short',
              day: 'numeric',
              hourCycle: 'h23',
              hour: "2-digit",
              minute: "2-digit"
            }

            let date = new Date(this.x).toLocaleString("en-US", options)
            let values = [date]

            values.push('')

            if (html_price.length > 0) {
              html_price.forEach(entry => {
                values.push(entry)
              })
            }

            values.push('')

            if (html_volume.length > 0) {
              values.push(html_volume)
            }

            values.push(`<div style="display: flex;">
                <b> ${total_volume_translation}:&nbsp;</b>
                <div style=" flex-grow: 1;"></div>
                <b> ${total_volume.toFixed(3)} MW </b>
              </div>`)

            return values.join('<br/>')
          },
          userOptions: {
            currencyCode: this.currencyCode,
            total_volume_translation: this.$t("dashboard.total_volume")
          },
        },
      }
    }
  },
  methods: {
    attachButtonListeners() {
      const chartContainer = this.$refs.highcharts.$el
      if (!chartContainer) {
        console.warn('Highcharts container not found.')
        return
      }

      const buttons = chartContainer.querySelectorAll('.highcharts-range-selector-buttons .highcharts-button')

      buttons.forEach((button) => {
        if (!button.dataset.listenerAttached) {
          const buttonText = button.textContent.trim()

          button.addEventListener('click', () => {
            const selectedButton = this.buttons.find(b => b.text === buttonText)
            if (selectedButton) {
              this.scope = selectedButton.text
            } else {
              console.warn(`No matching button found for text: "${buttonText}"`)
            }
          })
          button.dataset.listenerAttached = true
        }
      })
    },
    async loadData() {
      this.ready = false
      let url = new URL('/dashboard/zoom_graph' + window.location.search, window.location.origin)
      url.searchParams.set('scope', this.scope)
      try {
        const {data} = await this.axios.get(url)
        this.prices_min_value = data.prices_min_value || 0
        this.volume_min_value = data.volume_min_value || 0
        this.volume_series = Array.isArray(data.volume) ? this.prepare_volume_series(data.volume) : []
        this.currencyCode = data.prices.currency_code || ''

        this.price_series = data.prices.series ? Object.entries(data.prices.series).map(([seriesName, seriesData], index) => ({
          name: seriesName,
          type: 'spline',
          data: seriesData,
          yAxis: 1,
          showInLegend: true,
          visible: index === 0,
          series_type: 'price',
          dataGrouping: {
            approximation: 'average',
          },
        })) : []
        this.ready = true
        this.key += 1 // Triggers component re-render
      } catch (error) {
        console.error('Error loading data:', error)
        this.ready = true
      }
    },
    prepare_volume_series(volume) {
      let series = volume.map(({data, color, asset_type}) => ({
        name: this.$t("dashboard.volume_for_asset_type", {type: this.$t(`power_plants.asset_class_types.${asset_type}/asset`)}),
        type: 'areaspline',
        color: color,
        data: data,
        yAxis: 0,
        series_type: 'volume'
      }))
      return series
    }
  },
  watch: {
    scope() {
      this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<template lang="pug">
span
  v-highcharts(
    v-show="ready"
    :key="key"
    :options="chart_options"
    constructor-type="stockChart"
    ref="highcharts"
  )
  v-progress-linear(v-if="!ready" indeterminate)
</template>
