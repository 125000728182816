<script>
import EnvFlowChips from './components/env_flow_chips.vue'
import WaterLevelChips from "./components/water_level_chips.vue"
import TechChips from "./components/tech_chips.vue"

export default {
  name: 'Header',
  components: {
    TechChips,
    WaterLevelChips,
    EnvFlowChips
  },
  props: {
    powerPlant: {
      type: Object,
      required: true
    },
    drilldownFacade: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  methods: {
    omNum(value) {
      return value.toFixed(3)
    }
  }
}
</script>

<template lang="pug">
v-row
  v-col
    .label {{ $t('om_dashboard.drilldown.regulation') }}
    div(v-if="drilldownFacade.regulation")
      | {{ drilldownFacade.regulation }}
    div(v-else)
      v-tooltip(bottom)
        template(v-slot:activator="{ on, attrs }")
          v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
        span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'regulation' }) }}
  v-col
    .label {{ $t('om_dashboard.drilldown.set_point') }}
    div(v-if="drilldownFacade.set_point_water_level_sensor")
      | {{ omNum(drilldownFacade.set_point_water_level_sensor.latest_value) }}
      | {{ drilldownFacade.set_point_water_level_sensor.unit || 'm' }}
    div(v-else)
      v-tooltip(bottom)
        template(v-slot:activator="{ on, attrs }")
          v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
        span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'set_point_water_level' }) }}
  v-col
    .label {{ $t('om_dashboard.drilldown.operation') }}
    v-chip(:label="true" :color="powerPlant.prod_status?.table?.chip_color")
      | {{ powerPlant.prod_status?.table?.text }}
  v-col
    .label {{ $t('om_dashboard.drilldown.start_up_mode') }}
    | {{ drilldownFacade.start_up_mode }}
  v-col
    .label {{ $t('om_dashboard.env_flow') }}
    EnvFlowChips(:env-flow-alarm="powerPlant?.env_flow_alarm")
  v-col
    .label {{ $t('om_dashboard.overflow') }}
    WaterLevelChips(:water-level-alarm="powerPlant?.water_level_alarm")
  v-col
    .label {{ $t('om_dashboard.technical') }}
    TechChips(:power-plant="powerPlant")
</template>
