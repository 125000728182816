<script>
import OMDashboardHelpers from '../../../../mixins/om_dashboard_helpers'
export default {
  props: {
    values: Array,
    additionalNames: Array
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      isNormalized: false,
      selectedSerie: 'current',
      currentPowerVoltageValues: []
    }
  },
  watch: {
    values(newValues) {
      this.currentPowerVoltageValues = newValues
    }
  },
  computed: {
    selectedSeries() {
      const serieKey = this.isNormalized ? `${this.selectedSerie}_pc` : this.selectedSerie;
      const milliseconds_in_15_minutes = 15 * 60 * 1000;
      return this.currentPowerVoltageValues.map((serie) => {
        if (serie === undefined) {
          return { title: '', values: [] };
        }
        if ((serie.title === '') || (Object.keys(serie.values).length === 0)) {
          return { title: serie.title, values: [] };
        }

        const timestamps = serie.values['timestamp'].map(ts => ts * milliseconds_in_15_minutes);
        const values = serie.values[serieKey];
        const length = timestamps.length;

        const result = new Array(length);

        for (let i = 0; i < length; i++) {
          result[i] = [timestamps[i], values[i]];
        }

        return {
          title: serie.title,
          values: result
        };
      });
    },
    hasData() {
      if (this.currentPowerVoltageValues.length > 0)
        return this.selectedSeries.map((s) => s.values.length).reduce((a, b) => a + b) > 0
      else return false
    },
    options() {
      const unit = this.isNormalized ? '`%' : 'A'
      const precision = this.isNormalized ? 2 : 3
      let serieColors = ['#53B1FD', '#0000AA', '#00FFBB', '#6F8435', '#876F49', '#E1522A']
      let series = this.selectedSeries.map((s, i) => ({
        type: 'line',
        name: s.title,
        marker: { enabled: false },
        color: serieColors[i],
        index: 0,
        data: s.values,
        yAxis: 0,
        legendIndex: this.additionalNames.includes(s.title) ? this.additionalNames.indexOf(s.title) + 1 : 0
      }))
      let unitText = {
        current: this.$t('om_dashboard.drilldown.dc_current_unit'),
        voltage: this.$t('om_dashboard.drilldown.dc_voltage_unit'),
        power: this.$t('om_dashboard.drilldown.dc_power_unit')
      }

      if (this.isNormalized) {
        unitText = {
          current: this.$t('om_dashboard.drilldown.dc_current_unit_normalized'),
          voltage: this.$t('om_dashboard.drilldown.dc_voltage_unit_normalized'),
          power: this.$t('om_dashboard.drilldown.dc_power_unit_normalized')
        }
      }

      return {
        chart: {
          zoomType: 'x'
        },
        tooltip: {
          shared: true,
          split: true,
          pointFormatter: function () {
            return `${this.series.name}: <b>${this.y.toFixed(precision)} ${unit}</b><br/>`
          }
        },
        title: { text: null },
        xAxis: [
          {
            type: 'datetime'
          }
        ],
        yAxis: [
          {
            title: {
              text: unitText['current'],
              enabled: this.selectedSerie === 'current'
            }
          },
          {
            title: {
              text: unitText['voltage'],
              enabled: this.selectedSerie === 'voltage'
            }
          },
          {
            title: {
              text: unitText['power'],
              enabled: this.selectedSerie === 'power'
            }
          }
        ],
        boost: {
          enabled: true,
          useGPUTranslations: true,
          seriesThreshold: 0,
          turboThreshold: 50000
        },
        credits: { enabled: false },
        series: series,
        exporting: this.isExportable(this.$t('om_dashboard.drilldown.export_menu.filenames.mppt_production'))
      }
    }
  },
  created() {
    this.currentPowerVoltageValues = this.values
  }
}
</script>

<template lang="pug">
div.mppt_production(v-if="hasData")
  .d-flex
    .legend(style="width: 25%")
      div(style="float:left; height: 40px")
        v-switch(v-model="isNormalized")
      div(style="float:left; height: 40px; padding-top: 4px; padding-left: 5px")
        p {{ $t('om_dashboard.drilldown.normalized_values') }}
    .flex-grow-1
    v-item-group.v-btn-toggle
      v-btn(:disabled="selectedSerie === 'current'" @click="selectedSerie = 'current'") {{ $t('om_dashboard.drilldown.current') }}
      v-btn(:disabled="selectedSerie === 'power'" @click="selectedSerie = 'power'") {{ $t('om_dashboard.drilldown.power') }}
      v-btn(:disabled="selectedSerie === 'voltage'" @click="selectedSerie = 'voltage'") {{ $t('om_dashboard.drilldown.voltage') }}
  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
