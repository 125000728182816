<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'

export default {
  name: 'OperationalStatusHours',
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    tech: {
      type: String,
    }
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {hidingNormal: false}
  },
  computed: {
    hasData() {
      return Array.isArray(this.values) && this.values.length > 0
    },
    opstatHours() {
      if (!Array.isArray(this.values)) {
        console.warn('Expected values to be an array, but got:', this.values)
        return []
      }
      if (this.hidingNormal) {
        return this.values.filter((v) => v[0] >= 40) // Exclude everything except underperforming, no_data and outage
      } else {
        return this.values
      }
    },

    summedSeries() {
      if (!Array.isArray(this.opstatHours)) {
        console.warn('opstatHours is not an array:', this.opstatHours)
        return []
      }

      const byStatus = this.opstatHours.map((osh) => {
        if (!Array.isArray(osh) || osh.length < 2) {
          console.warn('Unexpected osh structure:', osh)
          return {
            y: 0,
            category: 'Unknown',
            hours: 0,
            color: '',
            minutes: 0,
            isNormal: false
          }
        }

        const statusCode = osh[0]
        const hoursValue = osh[1]
        const operationalStatus = this.operationalStatusTypes[statusCode] || {}

        return {
          y: hoursValue,
          category: this.$t(`om_dashboard.status.${this.tech}.${operationalStatus.title || 'unknown'}`),
          hours: parseInt(hoursValue, 10),
          color: operationalStatus.color || '',
          minutes: this.calculateMinutes(hoursValue),
          isNormal: statusCode === 10
        }
      })

      const summed = this.opstatHours
          .map((b) => b[1] || 0)
          .reduce((a, b) => a + b, 0)

      return [
        ...byStatus.sort((a, b) => {
          if (a.isNormal === b.isNormal) return a.y - b.y
          return a.isNormal ? -1 : 1
        }),
        {
          isSum: true,
          y: summed,
          hours: parseInt(summed, 10),
          minutes: this.calculateMinutes(summed),
          category: this.$t('power_plants.total_hours'),
          color: '#999'
        }
      ]
    },
    options() {
      if (!Array.isArray(this.summedSeries)) {
        console.warn('summedSeries is not an array:', this.summedSeries)
        return {}
      }

      let series = [
        {
          type: 'waterfall',
          name: this.$t('om_dashboard.drilldown.accumulated_hours_of_operational_statuses'),
          color: '#53B1FD',
          data: this.summedSeries,
          tooltip: {
            pointFormat: '{point.hours}h {point.minutes}m'
          }
        }
      ]
      return {
        title: { text: null },
        credits: { enabled: false },

        xAxis: {
          categories: this.summedSeries.map((i) => i.category),
          labels: {
            style: {
              color: '#00000061',
              fontFamily: 'Inter'
            }
          },
          reversed: true,
          title: { text: null }
        },
        yAxis: [
          {
            type: 'linear',
            title: { text: this.$t('om_dashboard.drilldown.hours') }
          }
        ],
        plotOptions: {
          waterfall: {
            pointWidth: 64,
            colorByPoint: true,
            colors: this.summedSeries.map((i) => i.color)
          }
        },
        legend: { enabled: false },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}%</b>'
        },
        series: series
      }
    }
  },
  methods: {}
}
</script>

<template lang="pug">
div(v-if="hasData")
  .d-flex
    .legend(style="width: 25%")
      div(style="float:left; height: 40px")
        v-switch(v-model="hidingNormal")
      div(style="float:left; height: 40px; padding-top: 4px; padding-left: 5px")
        p {{ $t('om_dashboard.drilldown.show_abnormal') }}

  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
