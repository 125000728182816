<script>
import ChartsOmDashboardWater from './components/water.vue'
import Toggle from './components/toggle.vue'
export default {
  name: 'FallLoss',
  components: { ChartsOmDashboardWater, Toggle },
  props: {
    powerPlant: {
      type: Object,
      required: true
    },
    drilldownFacade: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hourlyFallLossData: null,
      visible: true
    }
  },
  computed: {
    fallLossSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.fall_loss
      return sensorId ? this.drilldownFacade.sensors.find((sensor) => sensor.tyde_id === sensorId) : null
    },
    waterLevelUpstreamSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.water_level
      return sensorId ? this.drilldownFacade.sensors.find((sensor) => sensor.tyde_id === sensorId) : null
    },
    waterLevelDownstreamSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.water_level_downstream
      return sensorId ? this.drilldownFacade.sensors.find((sensor) => sensor.tyde_id === sensorId) : null
    },
    penstockPressure() {
      return this.drilldownFacade?.penstock_pressure || null
    },
    intakes() {
      return this.drilldownFacade?.intakes?.intake_names
    },
    customDescription() {
      return this.fallLossSensor?.description ?? this.$t('om_dashboard.drilldown.fall_loss')
    }
  },
  methods: {
    omNum(value) {
      return value ? value.toFixed(3) : '0.00'
    },
    toggleVisible(value) {
      this.visible = value
    },
    async fetchHourlyFallLoss() {
      try {
        if (this.fallLossSensor) {
          const response = await this.axios.get(
            `/om_dashboard/sensor_data/adjusted?sensor_name=${this.fallLossSensor.name}&start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
          )

          this.hourlyFallLossData = response.data
          if (!this.fallLossSensor?.unit || this.fallLossSensor?.unit == 'cm')
            this.hourlyFallLossData = this.hourlyFallLossData.map((d) => [d[0], d[1] * 100])

          if (!this.hourlyFallLossData || this.hourlyFallLossData.length === 0) {
            console.warn('Time series data is empty')
          }
        } else {
          console.warn('Fall loss sensor is not available')
        }
      } catch (error) {
        console.error('Error fetching hourly fall loss data:', error)
      }
    },
    async fetchLatestValue(sensor) {
      if (sensor) {
        try {
          const response = await this.axios.get(
              `/om_dashboard/sensor_data/adjusted_latest_value/?sensor_name=${sensor.name}`
          )
          sensor.latest_value = response.data.value
        } catch (error) {
          console.error(`Error fetching latest value for sensor ${sensor.name}:`, error)
        }
      }
    },
  },
  async mounted() {
    await this.fetchHourlyFallLoss()
    await this.fetchLatestValue(this.fallLossSensor)
    await this.fetchLatestValue(this.fallLossIntake)
    await this.fetchLatestValue(this.penstockPressure)
  }
}
</script>

<template lang="pug">
div
  .heading
    Toggle(:visible="visible" @changeVisible="toggleVisible")
    span.header-text {{ customDescription }}
    span.grey--text.pr-4 ({{ drilldownFacade?.chart_range_description }})
    v-chip(v-if="fallLossSensor" :label="true")
      | {{ fallLossSensor.latest_value ? fallLossSensor.latest_value.toFixed(2) : '0.00' }}
      | {{ fallLossSensor.unit || 'cm' }}
  v-row(no-gutters v-show="visible")
    v-col(cols="10").pr-6
      ChartsOmDashboardWater(
        v-if="fallLossSensor"
        :data="hourlyFallLossData"
        name="Fall loss"
        :upper_threshold="fallLossSensor?.custom_upper_limit"
        :unit="fallLossSensor?.unit || 'cm'"
      )
      div(v-else class="mt-2")
        span.grey--text {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'fall_loss' }) }}
    v-col(cols="2")
      .details
        div
          div {{ $t('om_dashboard.drilldown.fall_loss') }}
          div(v-if="fallLossSensor")
            | {{ omNum(fallLossSensor.latest_value) }} cm
          div(v-else)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
              span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'fall_loss' }) }}
        div
          div {{ $t('om_dashboard.drilldown.penstock_pressure') }}
          div(v-if="penstockPressure")
            | {{ omNum(penstockPressure.latest_value) }} m
          div(v-else)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
              span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'penstock_pressure' }) }}
        div
          div {{ $t('om_dashboard.drilldown.fall_loss_penstock') }}
          div
            div(v-if="powerPlant?.asset_class?.penstocks?.length")
              | {{ omNum(powerPlant.asset_class.penstocks.map(penstock => penstock.net_head_loss_percent).reduce((a, b) => a + b, 0) / powerPlant.asset_class.penstocks.length) }} %
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'penstock_loss' }) }}
        div
          div {{ $t('activerecord.attributes.hydro/intake.intake_type') }}
          div
            | {{ intakes }}
</template>
