<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
export default {
  props: {
    values: Array,
    additionalNames: Array,
    unit: String,
    canNormalize: Boolean
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      chartValues: this.values,
      chartTitles: {
        measured: this.$t('om_dashboard.drilldown.measured'),
        measured_norm: this.$t('om_dashboard.drilldown.measured'),
        theoretical: this.$t('om_dashboard.drilldown.theoretical'),
        theoretical_norm: this.$t('om_dashboard.drilldown.theoretical'),
        potential: this.$t('om_dashboard.drilldown.potential'),
        potential_loss: this.$t('om_dashboard.overview.loss'),
        unavailable: this.$t('om_dashboard.drilldown.unavailable'),
        unavailable_norm: this.$t('om_dashboard.drilldown.unavailable'),
        weather_model: this.$t('om_dashboard.drilldown.weather_model'),
        no_data: this.$t('om_dashboard.alarm_status.no_data')
      },
      isNormalized: false
    }
  },
  watch: {
    values() {
      this.chartValues = this.values
    }
  },
  computed: {
    hasComparisons() {
      return this.chartValues[0].title !== 'measured'
    },
    hasData() {
      return this.chartValues.length > 0
    },
    visibleValues () {
      if (this.isNormalized) {
        if (this.additionalNames.length)
          return this.chartValues.map((cv) => {
            return { title: cv.title, values: cv.measured_norm_series }
          })
        else return this.chartValues.filter(i => i.title.match(/_norm$/))
      }
      else {
        if (this.additionalNames.length)
          return this.chartValues.map((cv) => {
            return { title: cv.title, values: cv.measured_series }
          })
        else
          return this.chartValues.filter(i => !i.title.match(/_norm$/))
      }
    },
    options() {
      let seriesColours = ['#53B1FD', '#0000AA', '#00FFBB', '#6F8435', '#876F49', '#E1522A']
      let defaultColours = ['#2E90FA', '#0000AA', '#00FFBB']
      const unit = this.isNormalized ? '%' : this.unit
      const precision = this.isNormalized ? 2 : 3
      let series = this.visibleValues
        .map((s, i) => {
          let seriesName = this.chartTitles[s.title] || s.title
          return {
            type: 'line',
            name: seriesName,
            marker: { enabled: false },
            color: this.hasComparisons ? seriesColours[i] : defaultColours[i],
            index: 0,
            yAxis: 0,
            data: s.values,
            legendIndex: this.additionalNames.includes(s.title)
              ? this.additionalNames.indexOf(s.title) + 1
              : 0
          }
        })

      return {
        chart: {
          zoomType: 'x'
        },
        tooltip: {
          shared: true,
          split: true,
          pointFormatter: function () {
            const unitText = unit ? ` ${unit}` : ''
            return `${this.series.name}: <b>${this.y.toFixed(precision)} ${unitText}</b><br/>`
          }
        },
        title: { text: null },
        xAxis: [
          {
            type: 'datetime'
          }
        ],
        yAxis: [
          {
            title: {
              text: this.isNormalized
                ? this.$t('om_dashboard.drilldown.normalized_power')
                : this.$t('om_dashboard.drilldown.power_y_title', { unit: this.unit })
            }
          }
        ],
        boost: {
          enabled: true,
          useGPUTranslations: true,
          seriesThreshold: 0,
          turboThreshold: 500000
        },
        credits: { enabled: false },
        series: series,
        exporting: this.isExportable(this.$t('om_dashboard.drilldown.export_menu.filenames.production_chart'))
      }
    }
  }
}
</script>

<template lang="pug">
div.production_chart(v-if="hasData")
  .d-flex(v-if="canNormalize")
    .legend(style="width: 25%")
        div(style="float:left; height: 40px")
          v-switch(v-model="isNormalized")
        div(style="float:left; height: 40px; padding-top: 4px; padding-left: 5px")
          p {{ $t('om_dashboard.drilldown.normalized_values') }}
  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
