<script>
import ChipRow from './chip_row.vue'

export default {
  name: 'WaterLevelChips',
  components: {
    ChipRow
  },
  props: {
    waterLevelAlarm: {
      type: Object,
      required: false,
      default: () => ({
        active: false,
        status: '',
        alarm_threshold: null
      })
    }
  },
  computed: {
    alarmObject() {
      return this.waterLevelAlarm
    },
    canShowChips() {
      return this.waterLevelAlarm && this.hasAlarmThreshold
    },
    hasSensor() {
      return !!this.waterLevelAlarm
    },
    hasAlarmThreshold() {
      return this.waterLevelAlarm.alarm_threshold != null
    }
  }
}
</script>

<template lang="pug">
div.water-level-chips
  ChipRow(v-if="canShowChips" :alarm-object="alarmObject")
  div(v-else-if="!hasSensor")
    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs }")
        v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
      span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'water_level' }) }}
  div(v-else-if="!hasAlarmThreshold")
    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs }")
        v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
      span {{ $t('om_dashboard.help.upper_alert_limit') }}
</template>
